import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Container,
  DateList,
  DateItem,
  TimeSlots,
  TimeSlot,
  ScrollButton,
  ProceedButton,
  ImageContainer,
  CenteredImage,
  Duration,
} from "./DateAvailabilityStyles";
import leftArrow from "../../Assets/Logo_icons/arrow_back.svg";
import rightArrow from "../../Assets/Logo_icons/forward_arrow.svg";
import { open_api } from "../../Utils/network";
import noSlot from "../../Assets/course_page/noSlots.png";
import { PayButton } from "./PaymentDialogStyles";

const generateDates = (days) => {
  const dates = [];
  const today = new Date();
  for (let i = 0; i < days; i++) {
    const date = new Date(today);
    date.setDate(today.getDate() + i);
    dates.push(date);
  }
  return dates;
};

const DateAvailability = ({
  expertId,
  onDateChange,
  onTimeSlotChange,
  onProceedToPayment,
  onSelectedSlot,
  onDurationChange,
  packageDuration,
  bypassPurchase,
}) => {
  const [selectedDate, setSelectedDate] = useState(null); // Start with no date selected
  const [timeSlots, setTimeSlots] = useState([]);
  const [availableDates, setAvailableDates] = useState([]); // To store dates with available slots
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [selectedDuration, setSelectedDuration] = useState(null);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [loadingSlots, setLoadingSlots] = useState(false);

  // Fetch available slots for each date in the next 30 days
  useEffect(() => {
    const fetchAvailableDates = async () => {
      try {
        const response = await open_api.get(
          `/session/slot_list_available?expert=${expertId}&duration=${packageDuration}`
        );

        // Extract the available slots from the API response
        const availableSlots = response?.data?.data || [];
        const available = availableSlots.map((slot) => new Date(slot.date));

        setAvailableDates(available);

        // Automatically select the first available date if it exists
        if (available.length > 0) {
          setSelectedDate(available[0]);
        }
      } catch (error) {
        console.error("Error fetching available dates:", error);
      }
    };

    fetchAvailableDates();
  }, [expertId, packageDuration]);

  // Fetch time slots for the selected date
  useEffect(() => {
    if (!selectedDate) return;

    const fetchTimeSlots = async () => {
      setLoadingSlots(true);

      const selectedDateStr = selectedDate.toISOString().split("T")[0]; // Format the date for the API

      try {
        const response = await open_api.get(
          `/session/slot_list?date=${selectedDateStr}&expert=${expertId}&duration=${packageDuration}`
        );
        setTimeSlots(response?.data?.data || []);
      } catch (error) {
        console.error("Error fetching time slots:", error);
        setTimeSlots([]); // Clear slots if there's an error
      } finally {
        setLoadingSlots(false);
      }
    };

    fetchTimeSlots();
  }, [selectedDate, expertId, packageDuration]);

  // Handle changes in selected date
  useEffect(() => {
    onDateChange(selectedDate);
  }, [selectedDate, onDateChange]);

  // Handle changes in selected time slot
  useEffect(() => {
    onTimeSlotChange(selectedTimeSlot);
  }, [selectedTimeSlot, onTimeSlotChange]);

  // Handle changes in selected duration
  useEffect(() => {
    onDurationChange(selectedDuration);
  }, [selectedDuration, onDurationChange]);

  // Handle changes in selected slot
  useEffect(() => {
    onSelectedSlot(selectedSlot);
  }, [selectedSlot, onSelectedSlot]);

  const scrollLeft = () => {
    document.getElementById("dateList").scrollBy({
      left: -80,
      behavior: "smooth",
    });
  };

  const scrollRight = () => {
    document.getElementById("dateList").scrollBy({
      left: 80,
      behavior: "smooth",
    });
  };

  const handleTimeSlotClick = (slot) => {
    if (slot.available) {
      setSelectedTimeSlot(slot.time);
      setSelectedDuration(convertDurationToMinutes(slot.duration));
      setSelectedSlot(slot);
    }
  };

  const formatTime = (time) => {
    const [hours, minutes, seconds] = time.split(":");
    const date = new Date();
    date.setHours(hours, minutes, seconds);
    return date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
  };

  const convertDurationToMinutes = (duration) => {
    const [hours, minutes, seconds] = duration.split(":").map(Number);
    return hours * 60 + minutes + Math.round(seconds / 60);
  };

  return (
    <Container>
      {availableDates.length == 0 ? (
        <>
          {" "}
          <ImageContainer>
            <CenteredImage src={noSlot} alt="No time slots available" />
          </ImageContainer>
        </>
      ) : (
        <>
          <div style={{ display: "flex", alignItems: "center" }}>
            <ScrollButton onClick={scrollLeft}>
              <img src={leftArrow} alt="Scroll Left" />
            </ScrollButton>
            <DateList id="dateList">
              {availableDates.map((date, index) => (
                <DateItem
                  key={index}
                  selected={
                    selectedDate &&
                    selectedDate.toISOString().split("T")[0] ===
                      date.toISOString().split("T")[0]
                  }
                  onClick={() => setSelectedDate(date)}
                >
                  {date.toDateString().split(" ")[0]} <br />
                  {date.getDate()} <br />
                  {date.toLocaleString("default", { month: "short" })}
                </DateItem>
              ))}
            </DateList>
            <ScrollButton onClick={scrollRight}>
              <img src={rightArrow} alt="Scroll Right" />
            </ScrollButton>
          </div>
          <h4>Select a time of day</h4>
          <div>
            {loadingSlots ? (
              <div>Loading time slots...</div>
            ) : timeSlots && timeSlots.length > 0 ? (
              <TimeSlots>
                {timeSlots.map((slot) => (
                  <TimeSlot
                    key={slot.id}
                    available={slot.available}
                    selected={selectedTimeSlot === slot.time}
                    onClick={() => handleTimeSlotClick(slot)}
                  >
                    {formatTime(slot.time)}
                    <Duration
                      available={slot.available}
                      selected={selectedTimeSlot === slot.time}
                    >
                      {convertDurationToMinutes(slot.duration)} mins
                    </Duration>
                  </TimeSlot>
                ))}
              </TimeSlots>
            ) : (
              <ImageContainer>
                <CenteredImage src={noSlot} alt="No time slots available" />
              </ImageContainer>
            )}
            <p>All times are in UTC +5:30</p>
          </div>
          <PayButton onClick={onProceedToPayment}>
            {!bypassPurchase ? "Proceed to payment" : "Confirm selection"}
          </PayButton>
        </>
      )}
    </Container>
  );
};

export default DateAvailability;
