import React, { useState } from "react";
import styled from "styled-components";
import { open_api } from "../../Utils/network";
import { useNavigate, useParams } from "react-router-dom"; // Import useNavigate from react-router-dom

// Import your assets
import contentBackground from "../../Assets/popup/designCurve.svg"; // Adjust the path
import logo from "../../Assets/popup/myburgoLogo.svg"; // Adjust the path

const ContentContainer = styled.div`
  background-size: cover;
  color: black;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 10px;

  @media (max-width: 768px) {
    width: 100%;
    height: auto;
    border-radius: 0 0 10px 10px;
  }
`;

const Logo = styled.div`
  width: 43%;
  height: 15%;
  background-image: url(${logo});
  background-size: contain;
  background-repeat: no-repeat;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    width: 60%;
    height: 10%;
    margin-bottom: 10px;
  }
`;

const Title = styled.div`
  font-size: 25px;
  margin-bottom: 20px;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 20px;
    margin-bottom: 10px;
  }
`;

const Form = styled.form`
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 90%;

  @media (max-width: 768px) {
    width: 90%;
    padding: 15px;
  }
`;

const Input = styled.input`
  width: calc(100% - 20px);
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const Button = styled.button`
  width: 100%;
  padding: 10px;
  background: #f27497;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: medium;
  font-weight: 700;
`;

const ErrorText = styled.div`
  color: red;
  font-size: 12px;
  margin-bottom: 10px;
`;

const ErrorMessage = styled.div`
  color: #ff0000;
  font-size: 12px;
  margin-top: -5px;
  margin-bottom: 10px;
`;

const EbookDialogContent = ({ ebookData, hideBg }) => {
  const [errors, setErrors] = useState({});
  const { expertslug, itemSlug } = useParams();
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState({
    name: "",
    email: "",
    phone: null,
    type: ebookData.type || itemSlug,
    expert: ebookData.expert,
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    let newErrors = {};
    if (!validateEmail(userDetails.email)) {
      newErrors.email = "Please enter a valid email address";
    }
    if (!validatePhone(userDetails.phone)) {
      newErrors.phone = "Please enter a valid phone number";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    setErrors({});
    open_api
      .post("/lead/leads/", userDetails)
      .then((res) => {
        if (res?.status === 200 || res?.status === 201 || res?.status === 204) {
          setUserDetails({
            name: "",
            email: "",
            phone: null,
          });
          if (ebookData.ebook) {
            fetch(ebookData.ebook)
              .then((response) => response.blob())
              .then((blob) => {
                const url = window.URL.createObjectURL(blob);

                const link = document.createElement("a");
                link.href = url;

                const fileName =
                  ebookData.ebook.split("/").pop() || "ebook.pdf";
                link.setAttribute("download", fileName);

                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                window.URL.revokeObjectURL(url);

                setTimeout(() => {
                  if (expertslug) {
                    navigate(`/experts/${expertslug}`);
                  }
                }, 5000);
              })
              .catch((error) => {
                console.error("Error downloading the file:", error);
              });
          } else {
            console.error("Ebook URL is not available");
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (errors[name]) {
      setErrors((prev) => ({ ...prev, [name]: "" }));
    }
  };

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return re.test(String(email).toLowerCase());
  };

  const validatePhone = (phone) => {
    const re =
      /^(?:([+]\d{1,4})[-.\s]?)?(?:[(](\d{1,3})[)][-.\s]?)?(\d{1,4})[-.\s]?(\d{1,4})[-.\s]?(\d{1,9})$/;
    return re.test(String(phone));
  };

  return (
    <ContentContainer hideBg={hideBg}>
      <Logo />
      <Title>{ebookData.title}</Title>
      <Form onSubmit={handleSubmit}>
        <Input
          type="text"
          name="name"
          placeholder="Name"
          value={userDetails.name}
          onChange={handleChange}
          required
        />
        <Input
          type="email"
          name="email"
          placeholder="Email"
          value={userDetails.email}
          onChange={handleChange}
          required
        />
        {errors.email && <ErrorMessage>{errors.email}</ErrorMessage>}
        <Input
          type="tel"
          name="phone"
          placeholder="Phone Number"
          value={userDetails.phone}
          onChange={handleChange}
          required
        />
        {errors.phone && <ErrorMessage>{errors.phone}</ErrorMessage>}
        {errors.submit && <ErrorMessage>{errors.submit}</ErrorMessage>}
        <Button type="submit">Download the ebook</Button>
      </Form>
    </ContentContainer>
  );
};

export default EbookDialogContent;
